import axios from "axios";
import store from "@/state/store";
import { useRouter } from "vue-router";
const router = useRouter();

const api = axios.create({
	baseURL: process.env.VUE_APP_API_URL || "http://localhost:9999",
	withCredentials: true,
	withXSRFToken: true,
});

api.interceptors.response.use(
	function (response) {
		return response;
	},
	function (error) {		
		if (error.response.status == 401 && error.response.statusText == "Unauthorized") {
			store.reset();
			localStorage.clear();
			sessionStorage.clear();
			router.push({
				path: "/login",
			});
		}
	}
);

export { api };
