import { api } from "../../services/api";

export const state = {
	files: [
		{
			id: 0,
			condo_id: 0,
			structure: {
				name: "example",
				path: "/condos/57046515000140/documents/",
				type: "dir",
				content: [
					{
						name: "photo.jpg",
						format: "jpg",
						type: "file",
						size: 234567,
						path: "/condos/57046515000140/documents/photo.jpg",
						last_modified: "2024-09-03T13:00:00Z",
						modified_by: "User ID",
					},
					{
						name: "photo.pdf",
						format: "pdf",
						type: "file",
						size: 234567,
						path: "/condos/57046515000140/documents/photo.pdf",
						last_modified: "2024-09-03T13:00:00Z",
						modified_by: "User ID",
					},
				],
			},
		},
		{
			id: 0,
			condo_id: 0,
			structure: {
				name: "example2",
				path: "/condos/57046515000141/documents/",
				type: "dir",
				content: [
					{
						name: "photo2.jpg",
						format: "jpg",
						type: "file",
						size: 234567,
						path: "/condos/57046515000140/documents/photo2.jpg",
						last_modified: "2024-09-03T13:00:00Z",
						modified_by: "User ID",
					},
					{
						name: "photo2.jpg",
						format: "jpg",
						type: "file",
						size: 234567,
						path: "/condos/57046515000140/documents/photo2.jpg",
						last_modified: "2024-09-03T13:00:00Z",
						modified_by: "User ID",
					},
				],
			},
		},
		{
			id: 2,
			condo_id: 1,
			structure: {
				name: "Sirius Vertical Houses",
				path: "/condos/51562832000132/documents/",
				type: "dir",
				content: [
					{
						name: "fotos",
						type: "dir",
						path: "/condos/57046515000140/documents/fotos/",
						content: [
							{
								name: "photo3",
								format: "png",
								type: "file",
								size: 234567,
								path: "/condos/57046515000140/documents/photo2",
								last_modified: "2024-09-03T13:00:00Z",
								modified_by: "User ID",
							}
						],
						created_at: "2024-09-04T14:16:58.000000Z",
						deleted_at: null,
						updated_at: "2024-09-04T14:16:58.000000Z",
						modified_by: "User ID",
					},
					{
						name: "photo2",
						format: "",
						type: "file",
						size: 234567,
						path: "/condos/57046515000140/documents/photo2",
						last_modified: "2024-09-03T13:00:00Z",
						modified_by: "User ID",
					}
				],
				created_at: "2024-09-04T14:16:58.000000Z",
				deleted_at: null,
				updated_at: "2024-09-04T14:16:58.000000Z",
			},
		},
		{
			id: 3,
			condo_id: 2,
			structure: {
				name: "teste",
				path: "/condos/57046515000140/documents/",
				type: "dir",
				content: [],
				created_at: "2024-09-04T14:16:58.000000Z",
				deleted_at: null,
				updated_at: "2024-09-04T14:16:58.000000Z",
			},
		},
	],
};

export const getters = {
	files: (state) => state.files,
	path: (state) => state.path,
};

export const mutations = {
	storeAll(state, payload) {
		state.files = payload;
	},
  addFolder(state, { condoIndex, parentPath, folderName }) {
    const newFolder = {
      name: folderName,
      type: "dir",
      path: `${parentPath}${folderName}/`,
      content: [],
      created_at: new Date().toISOString(),
      updated_at: new Date().toISOString(),
      modified_by: "User ID", // Pode ser substituído pelo ID real do usuário
    };

    let targetFolder = state.files[condoIndex].structure;

    if (parentPath === targetFolder.path) {
      targetFolder.content.push(newFolder);
    } else {
      const parentFolder = findFolder(targetFolder.content, parentPath);
      if (parentFolder) {
        parentFolder.content.push(newFolder);
      }
    }
  },
	addFileToStructure(state, {condoIndex, file}) {
		const { name, format, size, path, modified_by } = file;
		const fileObject = {
			name,
			format,
			type: "file",
			size,
			path,
			last_modified: new Date().toISOString(),
			modified_by
		};
	
		const parentPath = path.substring(0, path.lastIndexOf("/") + 1); // Caminho do diretório pai
		let targetFolder = state.files[condoIndex].structure;
	
		if (parentPath === targetFolder.path) {
			targetFolder.content.push(fileObject);
		} else {
			const parentFolder = findFolder(targetFolder.content, parentPath);
			if (parentFolder) {
				parentFolder.content.push(fileObject);
			} else {
				console.error("Diretório não encontrado!");
			}
		}
	}
};

export const actions = {
	get({ commit }, { condos }) {
		let url = `/api/user/files/get`;

		return api.post(url, condos).then((response) => {
			console.log(response.data);
			commit("storeAll", response.data);
		});
	},
	addFolder({ commit }, payload) {
		console.log(payload);
    commit('addFolder', payload);
  },

	addFile({ commit }, payload) {
		console.log(payload);
    commit('addFolder', payload);
  },
};

const findFolder = (content, path) => {
	for (let item of content) {
		if (item.type === "dir" && item.path === path) {
			return item;
		} else if (item.type === "dir") {
			let found = findFolder(item.content, path);
			if (found) return found;
		}
	}
	return null;
};