import { api } from "../../services/api.js";

export const state = {
	systems: [],
};

export const mutations = {
	store(state, payload) {
		state.systems = payload;
	},
};

export const actions = {
	get({ commit }) {
		return api.get(`/api/building-systems`).then((response) => {
			commit("store", response.data);
		});
	}
};
