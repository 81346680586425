import { api } from "../../services/api.js";

export const state = {
	humanResources: [],
	humanResourcesSearch: [],
	currentUpdate: {},
	currentUpdateIndex: null,
	page: null,
	showModalCreate: false,
	showModalUpdate: false,
	viewMode: "list",
  filters: {
		type: null
	},
};

export const getters = {
	humanResources: (state) => state.humanResources,
	humanResourcesSearch: (state) => state.humanResourcesSearch
};

export const mutations = {
	storeAll(state, payload) {
		state.humanResources = payload;
	},
	storePage(state, payload) {
		state.page = payload;
	},
	push(state, payload) {
		state.humanResources.push(...payload);
	},
	store(state, payload) {
		state.humanResources.push(payload);
	},
	storeSearch(state, payload) {
		state.humanResourcesSearch = payload;
	},
	update(state, { payload, index }) {
		const item = state.humanResources[index];
		Object.assign(item, payload);
	},
	remove(state, payload) {
		state.humanResources = state.humanResources.filter(
			(location) => location.id != payload
		);
	},

	changeAssetResponsible(state, {responsible, asset}) {
		const newResponsible = state.humanResources.filter(responsibleItem => responsibleItem.id == responsible.id);
		const oldResponsible = state.humanResources.filter(responsibleItem => responsibleItem.assets.some(assetItem => assetItem.id === asset.id))

		const assetsOld = oldResponsible[0].assets
		const updatedAssetsOld = assetsOld.filter(assetItem => assetItem.id !== asset.id)
		const updatedResponsibleOld = oldResponsible[0];
		updatedResponsibleOld.assets = updatedAssetsOld;
		
		const updatedResponsibleNew = newResponsible[0];
		updatedResponsibleNew.assets.push(asset);

		Object.assign(oldResponsible[0], updatedResponsibleOld)
		Object.assign(newResponsible[0], updatedResponsibleNew)
	},

	toggleModalCreate(state) {
		state.showModalCreate = !state.showModalCreate;
	},
	toggleModalUpdate(state, { payload, index }) {
		state.currentUpdate = payload;
		state.currentUpdateIndex = index;
		state.showModalUpdate = !state.showModalUpdate;
	},

	toggleViewMode(state, mode) {
		state.viewMode = mode;
	}
};

export const actions = {
	get({ commit }, { nextPage, condoId }) {
		let url = `/api/user/human-resources?condo_id=${condoId}`;

		if (nextPage != null) {
			url += `&page=${nextPage}`;
		}

		return api.get(url).then((response) => {
			if (nextPage != null) {
				commit("push", response.data.data);
			} else {
				commit("storeAll", response.data.data);
			}

			delete response.data["data"];
			commit("storePage", response.data);
		});
	},
	search({ commit }, data) {
		return api.post("/api/user/human-resources/search", data).then((response) => {
			commit("storeSearch", response.data);
		});
	},
	create({ commit }, data) {
		return api.post("/api/user/human-resources", data).then((response) => {
			commit("store", response.data);
		});
	},
	update({ commit }, { data, id, index }) {
		return api
			.put(`/api/user/human-resources/${id}`, { ...data, id })
			.then((response) => {
				commit("update", { payload: response.data, index: index });
			});
	},
	updateCover({ commit }, { data, id, index }) {
		return api
			.post(`/api/user/human-resources/cover/${id}`, data).then((response) => {
				commit("update", { payload: response.data, index: index });
			});
	},
	remove({ commit }, id) {
		return api.delete(`/api/user/human-resources/${id}`).then((response) => {
			if (response.status === 200) {
				commit("remove", id);
				return;
			}

			throw response;
		});
	},
};
