import { Store } from 'vuex'
import { createStore } from 'vuex-extensions'
import VuexPersistence from 'vuex-persist'

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  modules: ['condominium', 'tower', 'block', 'location'],
  // strictMode: process.env.NODE_ENV !== 'production',
})

import modules from './modules'

const store = createStore(Store, {
  modules,

  plugins: [vuexLocal.plugin],
  // Enable strict mode in development to get a warning
  // when mutating state outside of a mutation.
  // https://vuex.vuejs.org/guide/strict.html
  // strict: process.env.NODE_ENV !== 'production',
})

export default store

