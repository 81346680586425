import store from "@/state/store";
import { api } from "../services/api";

export default [
	{
		path: "/login",
		name: "login",
		component: () => import("../views/account/login.vue"),
		meta: {
			title: "Login",
			beforeResolve(routeTo, routeFrom, next) {
				// If the user is already logged in
				if (store.getters["auth/loggedIn"]) {
					// Redirect to the home page instead
					next({ name: "default" });
				} else {
					store.reset();
					localStorage.clear();
					sessionStorage.clear();
					// Continue to the login page
					next();
				}
			},
		},
	},
	{
		path: "/register",
		name: "Register",
		component: () => import("../views/account/register.vue"),
		meta: {
			title: "Register",
			beforeResolve(routeTo, routeFrom, next) {
				// If the user is already logged in
				if (store.getters["auth/loggedIn"]) {
					// Redirect to the home page instead
					next({ name: "default" });
				} else {
					// Continue to the login page
					next();
				}
			},
		},
	},
	{
		path: "/forgot-password",
		name: "Forgot password",
		component: () => import("../views/account/forgot-password.vue"),
		meta: {
			title: "Forgot Password",
			beforeResolve(routeTo, routeFrom, next) {
				// If the user is already logged in
				if (store.getters["auth/loggedIn"]) {
					// Redirect to the home page instead
					next({ name: "default" });
				} else {
					// Continue to the login page
					next();
				}
			},
		},
	},
	{
		path: "/logout",
		name: "logout",
		meta: {
			title: "Logout",
			authRequired: true,
			beforeResolve(routeTo, routeFrom, next) {
				store.reset();
				localStorage.clear();
				sessionStorage.clear();

				api.post("/api/user/logout");

				next();
			},
		},
		component: () => import("../views/auth/logout/basic"),
	},
	{
		path: "/condominios",
		name: "condos",
		meta: {
			title: "Condomínios",
			authRequired: true,
		},
		component: () => import("../views/condominium/index"),
	},
	{
		path: "/condominio",
		name: "condominium",
		meta: {
			title: "Condomínio",
			authRequired: true,
		},
		component: () => import("../views/condominium/show"),
	},
	{
		path: "/",
		name: "default",
		meta: {
			title: "Dashboard",
			authRequired: true,
		},
		component: () => import("../views/dashboard/index.vue"),
	},
	{
		path: "/relatorios",
		name: "reports",
		meta: {
			title: "Relatórios",
			authRequired: true,
		},
		component: () => import("../views/reports/index"),
	},
	{
		path: "/manutencoes/preventiva",
		name: "preventive_maintances",
		meta: {
			title: "Manutenções Preventivas",
			authRequired: true,
		},
		component: () => import("../views/maintence/index"),
	},
	{
		path: "/manutencoes/corretiva",
		name: "corrective_maintances",
		meta: {
			title: "Manutenções Corretivas",
			authRequired: true,
		},
		component: () => import("../views/maintence/index"),
	},
	{
		path: "/chamados",
		name: "calls",
		meta: {
			title: "Chamados",
			authRequired: true,
		},
		component: () => import("../views/call/index"),
	},
	{
		path: "/comunicacao",
		name: "comunication",
		meta: {
			title: "Comunicação",
			authRequired: true,
		},
		component: () => import("../views/comunication/index"),
	},
	{
		path: "/enquetes",
		name: "survey",
		meta: {
			title: "Enquetes",
			authRequired: true,
		},
		component: () => import("../views/survey/index"),
	},
	{
		path: "/contratos",
		name: "contracts",
		meta: {
			title: "Contratos",
			authRequired: true,
		},
		component: () => import("../views/contracts/index"),
	},
	{
		path: "/documentos",
		name: "documents",
		meta: {
			title: "Documentos",
			authRequired: true,
		},
		component: () => import("../views/documents/index"),
	},
	{
		path: "/work-flows",
		name: "work-flows",
		meta: {
			title: "Work-Flow",
			authRequired: true,
		},
		component: () => import("../views/work-flow/index"),
	},
];
