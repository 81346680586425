import { api } from "../../services/api.js";

export const state = {
	towers: [],
	towersSearch: [],
	currentUpdate: {},
	currentUpdateIndex: null,
	page: null,
	showModalCreate: false,
	showModalUpdate: false,
};

export const getters = {
	towers: (state) => state.towers,
	towersSearch: (state) => state.towersSearch
};

export const mutations = {
	storeAll(state, payload) {
		state.towers = payload;
	},
	storePage(state, payload) {
		state.page = payload;
	},
	store(state, payload) {
		state.towers.push(payload);
	},
	storeSearch(state, payload) {
		state.towersSearch = payload;
	},
	update(state, { payload, index }) {
		const item = state.towers[index];
		Object.assign(item, payload);
	},
	remove(state, payload) {
		state.towers = state.towers.filter(
			(tower) => tower.id != payload
		);
	},


	toggleModalCreate(state) {
		state.showModalCreate = !state.showModalCreate;
	},
	toggleModalUpdate(state, { payload, index }) {
		state.currentUpdate = payload;
		state.currentUpdateIndex = index;
		state.showModalUpdate = !state.showModalUpdate;
	},
};

export const actions = {
	get({ commit }, { nextPage, condoId }) {
		let url = `/api/user/towers?condo_id=${condoId}`;

		if (nextPage != null) {
			url += `&page=${nextPage}`;
		}

		return api.get(url).then((response) => {
			commit("storeAll", response.data.data);

			delete response.data["data"];
			commit("storePage", response.data);
		});
	},
	search({ commit }, data) {
		return api.post("/api/user/towers/search", data).then((response) => {
			commit("storeSearch", response.data);

			return response
		});
	},
	create({ commit }, data) {
		return api.post("/api/user/towers", data).then((response) => {
			commit("store", response.data);
		});
	},
	update({ commit }, { data, id, index }) {
		return api
			.put(`/api/user/towers/${id}`, { ...data, id })
			.then((response) => {
				commit("update", { payload: response.data, index: index });
			});
	},
	remove({ commit }, id) {
		return api
			.delete(`/api/user/towers/${id}`)
			.then((response) => {
				if (response.status === 200) {
					commit("remove", id);
					return;
				}

				throw response;
			});
	},
};
