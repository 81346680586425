import { api } from "../../services/api.js";

export const state = {
	blocks: [],
	blocksSearch: [],
	currentUpdate: {},
	currentUpdateIndex: null,
	page: null,
	showModalCreate: false,
	showModalUpdate: false,
};

export const getters = {
	blocks: (state) => state.blocks,
	currentUpdate: (state) => state.currentUpdate,
	blocksSearch: (state) => state.blocksSearch,
};

export const mutations = {
	storeAll(state, payload) {
		state.blocks = payload;
	},
	storePage(state, payload) {
		state.page = payload;
	},
	store(state, payload) {
		state.blocks.push(payload);
	},
	storeSearch(state, payload) {
		state.blocksSearch = payload;
	},
	update(state, { payload, index }) {
		const item = state.blocks[index];
		Object.assign(item, payload)
	},
	remove(state, payload) {
		state.blocks = state.blocks.filter((block) => block.id != payload);
	},

	toggleModalCreate(state) {
		state.showModalCreate = !state.showModalCreate;
	},
	toggleModalUpdate(state, { payload, index }) {
		state.currentUpdate = payload;
		state.currentUpdateIndex = index;
		state.showModalUpdate = !state.showModalUpdate;
	},
};

export const actions = {
	get({ commit }, { nextPage, condoId }) {
		let url = `/api/user/blocks?condo_id=${condoId}`;

		if (nextPage != null) {
			url += `&page=${nextPage}`;
		}

		return api.get(url).then((response) => {
			commit("storeAll", response.data.data);

			delete response.data["data"];
			commit("storePage", response.data);
		});
	},
	search({ commit }, data) {
		return api.post("/api/user/blocks/search", data).then((response) => {
			commit("storeSearch", response.data);

			return response
		});
	},
	create({ commit }, data) {
		return api.post("/api/user/blocks", data).then((response) => {
			commit("store", response.data);
		});
	},
	update({ commit }, { data, id, index }) {
		return api
			.put(`/api/user/blocks/${id}`, { ...data, id })
			.then((response) => {
				commit("update", { payload: response.data, index: index });
			});
	},
	remove({ commit }, id) {
		return api.delete(`/api/user/blocks/${id}`).then((response) => {
			if (response.status === 200) {
				commit("remove", id);
				return;
			}

			throw response;
		});
	},
};
